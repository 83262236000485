<template>
  <section class="l-content">
    <el-card name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="记录状态">
          <el-radio-group v-model="condition.borrowState" @change="getPageData()">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="0">申请中</el-radio-button>
            <el-radio-button label="1">已借出</el-radio-button>
            <el-radio-button label="8">申请归还</el-radio-button>
            <el-radio-button label="9">拒绝借出</el-radio-button>
            <el-radio-button label="10">已归还</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="使用时间">
          <l-datepicker type="daterange" :default="[condition.startDate, condition.endDate]" @change="dataRangeChange"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" placeholder="资产名称/使用人姓名"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getPageData()">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" size="small" @click="doExport()">导出</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="carNo" slot-scope="scope">
          <el-button type="text" @click="$site.view_car_detail(scope.row.carId)">{{ scope.row.carNo }}</el-button>
        </template>
        <template slot="borrowState" slot-scope="scope">
          <span v-if="scope.row.borrowState == 0" class="text-blue">申请中</span>
          <span v-else-if="scope.row.borrowState == 1" class="text-orange">已借出</span>
          <span v-else-if="scope.row.borrowState == 8" class="text-brown">申请归还</span>
          <span v-else-if="scope.row.borrowState == 9" class="text-red">拒绝借出</span>
          <span v-else-if="scope.row.borrowState == 10" class="text-green">已归还</span>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'BorrowList',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '车牌号', slot: 'carNo' },
          { label: '责任人', prop: 'reponsibleUserName' },
          { label: '使用人', prop: 'borrowUserName' },
          { label: '使用时间', prop: 'borrowTime', filter: (a, data) => this.$lgh.limitTo(a, 16) },
          { label: '归还时间', prop: 'confirmReturnTime', filter: (a, data) => this.$lgh.limitTo(a, 16) },
          { label: '使用目的', prop: 'purpose' },
          { label: '状态', slot: 'borrowState' }
        ]
      },
      condition: {
        keyword: '',
        // enterpriseCode: this.userInfo.enterpriseCode,
        borrowState: '',
        startDate: '',
        endDate: '',
        pageIndex: 1,
        pageSize: 20
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/ecarborrow/list', ['enterpriseCode', this.condition])
    },
    doExport() {
      const h = this.$createElement

      this.$lgh.get('api/enterprise/ecarborrow/export', ['enterpriseCode', this.condition]).then((res) => {
        this.$lgh.msgSuccess(h('a', { attrs: { target: '_blank', href: res.link_url, download: '导出文件' + new Date().valueOf() } }, '点击下载'))
      })
    }
  }
}
</script>
